.theme-region-div {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  display: flex;
}

.theme-region-div .theme-toggle-div {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.theme-region-div .theme-toggle-div .background {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.theme-region-div .theme-toggle-div .theme-toggle-icon {
  color: var(--icon-color);
  font-size: 1.75rem;
  position: relative;
}

.theme-region-div .theme-toggle-div:hover .theme-toggle-icon {
  color: var(--icon-hover-color);
}

.theme-region-div .theme-toggle-div:hover .background {
  opacity: 1;
  background-color: var(--icon-hover-background);
}

.theme-region-div .theme-toggle-div .region-toggle-icon {
  color: var(--icon-color);
  font-size: 1.35rem;
  position: relative;
}

.theme-region-div .theme-toggle-div:hover .region-toggle-icon {
  color: var(--icon-hover-color);
}

.theme-region-div .theme-toggle-div .sticky {
  color: var(--nav-bar-sticky-color) !important;
}

.theme-region-div .theme-toggle-div:hover .sticky {
  background: none;
}

.theme-region-div .region-toggle-div {
  color: var(--icon-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 1rem;
  padding-left: 1rem;
  width: 4.5rem; /*localization change*/
  border-left: var(--icon-color) 1px solid;
}

.theme-region-div .one-theme {
  border-left: none !important;
}

.theme-region-div .region-toggle-div-sticky {
  border-left: var(--nav-bar-sticky-color) 1px solid;
}

.theme-region-div .region-toggle-div .region-text {
  font-size: 1rem;
  opacity: 0.75;
  transition: opacity 0.25s, font-weight 0.25s;
  cursor: pointer;
}

.theme-region-div .region-toggle-div .sticky {
  color: var(--nav-bar-sticky-color) !important;
}

.theme-region-div .region-toggle-div .picked {
  font-weight: bold;
  opacity: 1;
}

.theme-region-div .region-toggle-div .picked {
  font-weight: bold;
  opacity: 1;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .theme-region-div {
    top: 1rem;
    right: 1rem;
  }

  .theme-region-div .region-toggle-div {
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    width: 4.25rem;
    justify-content: space-evenly;
  }
}
