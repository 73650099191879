.operations-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  /*margin-top: calc(var(--total-balance-div) + var(--filter-height) + 1rem);*/
}

.operations-div .operation-card {
  width: 75%;
  aspect-ratio: 1.5;
  border-radius: var(--div-border-radius);
  color: var(--operation-card-text-color);
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  animation: fade-in-up 0.6s;
}

.operations-div .operation-card:hover {
  box-shadow: none;
}

.operations-div .operation-card .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-around;
  align-items: start;
  text-align: start;
  z-index: 10;
  filter: 0;
  transition: filter 0.5s;
}

.operations-div .operation-card:hover .text {
  filter: blur(3px);
  transition: none;
}

.operations-div .operation-card .text .title {
  font-size: 1.15rem;
  font-weight: bold;
}

.operations-div .operation-card .text .description {
  font-size: 0.85rem;
  font-style: italic;
}

.operations-div .operation-card .icon {
  width: 2rem;
  height: 2rem;
  color: var(--operation-card-text-color);
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  margin: auto;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s;
}

.operations-div .operation-card:hover .icon {
  opacity: 1;
  transition: none;
}

.operations-div .operation-card .image {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: 0;
  transition: filter 0.5s;
}

.operations-div .operation-card:hover .image {
  filter: blur(3px);
  transition: filter 0.5s;
}

.operations-div .operation-card .image-src-1 {
  background: var(--operation-cards-background);
}

.operations-div .operation-card .image-src-2 {
  background: var(--operation-cards-background);
  transform: scaleX(-1);
}

.operations-div .operation-card .text .scroller {
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.operations-div .operation-card .text .scroller > span {
  top: 0;
  position: absolute;
  animation: vertical-scroll 7s infinite;
  color: var(--inside-highlight-color);
  font-weight: bold;
}

@media only screen and (max-width: 450px) {
  .operations-div {
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem 0;
    padding: 0rem;
  }

  .operations-div .operation-card {
    width: 49%;
    margin-bottom: 0;
    aspect-ratio: 1.15;
  }

  .operations-div .operation-card:hover {
    box-shadow: unset;
  }

  .operations-div .operation-card:hover .text {
    filter: unset;
  }

  .operations-div .operation-card:hover .icon {
    opacity: unset;
  }

  .operations-div .operation-card:hover .image {
    filter: unset;
  }
}
