.payment-input-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.payment-input-body {
  width: 100%;
  display: flex;
}

.payment-input-body .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.payment-input-body .section .right-div {
  width: 50%;
  padding: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  flex-direction: column;
}

.payment-input-body .section .id-input {
  width: 50%;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  position: relative;
  animation: fade-in-up 0.6s;
}

.payment-input-body .section .id-input .background {
  background-color: var(--div-background);
  border-radius: var(--div-border-radius);
  opacity: var(--div-opacity);
  width: 100%;
  height: 100%;
  position: absolute;
}

.payment-input-body .section .id-input .content {
  border-radius: var(--div-border-radius);
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-input-body .section .id-input .content .form-div {
  height: 5rem;
  width: 100%;
}

.payment-input-body .section .id-input .content .input-payment-id .input-label {
  color: var(--inside-div-text-color);
  display: flex;
}

.payment-input-body
  .section
  .id-input
  .content
  .input-payment-id
  .input-text:focus {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

.payment-input-body
  .section
  .id-input
  .content
  .input-payment-id
  .search-button {
  background-color: #e9ecef;
  color: var(--card-color);
  border: none;
}

.payment-input-body .section .id-input .content .form-div .description-text {
  color: var(--inside-div-text-color);
  height: 100%;
  text-align: start;
  font-style: italic;
  display: flex;
  align-items: center;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.payment-input-body .section .id-input .content .form-div .danger {
  color: var(--inside-danger-color);
  animation: bounce 0.5s;
}

.payment-input-body .section .value-input-waiting {
  width: calc(50% - 1rem);
  height: 13.5rem;
  animation: fade-in-up 0.6s;
  border-radius: var(--div-border-radius);
  position: relative;
  border: var(--outside-highlight-color) dashed 2.75px;
}

.payment-input-body .section .value-input-waiting .background {
  border-radius: var(--div-border-radius);
  opacity: var(--div-opacity);
  width: 100%;
  height: 100%;
  position: absolute;
}

.payment-input-body .section .value-input-waiting .content {
  border-radius: var(--div-border-radius);
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  z-index: 1;
  position: relative;
  color: var(--outside-div-text-color);
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.payment-input-body .section .value-input {
  width: 100%;
  padding: 1.25rem 0 0 0;
  animation: fade-in-up 0.6s;
  display: flex;
  flex-direction: column;
}

.payment-input-body .section .value-input .input-payment-id .input-label {
  color: var(--inside-div-text-color);
  display: flex;
}

.payment-input-body .section .value-input .input-payment-id .input-text {
  flex-grow: 1;
}

.payment-input-body .section .favorite-div {
  margin-top: 3rem;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: end;
  border-radius: var(--div-border-radius);
}

.payment-input-body .section .favorite-div .favorite-icon {
  font-size: calc(2rem * var(--font-size-scale));
  margin-right: 0.25rem;
  color: var(--inside-highlight-color);
}

.payment-input-body .section .favorite-div .add-text {
  color: var(--inside-highlight-color);
  text-align: start;
  height: 50%;
  font-size: calc(0.95rem * var(--font-size-scale));
  font-weight: 600;
}

.payment-input-body .section .account-info {
  margin-top: 1rem;
  display: flex;
}

.payment-input-body .section .account-info .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.payment-input-body .section .account-info .text {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: var(--inside-div-text-color);
  width: calc(100% - 4rem);
}

.payment-input-body .section .account-info .text .title {
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: bold;
}

.payment-input-body .section .account-info .text .id {
  font-size: calc(0.9rem * var(--font-size-scale));
}

.payment-input-body .section .account-info .text .type {
  font-size: calc(0.9rem * var(--font-size-scale));
  font-weight: 100;
  font-style: italic;
}

.payment-input-body .loader {
  width: 4rem !important;
  height: 4rem !important;
}

.payment-input-body .error-form-text {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .payment-input-container {
  }

  .payment-input-container .payment-input-body .middle-container {
    width: 100%;
  }

  .payment-input-body .section {
    flex-direction: column;
  }

  .payment-input-body .section .id-input {
    width: 100%;
  }

  .payment-input-body .section .id-input .content {
    padding: 1rem;
  }

  .payment-input-body .section .value-input-waiting {
    display: none;
  }

  .payment-input-body .section .value-input {
    padding: 0rem;
  }

  .payment-input-body .section .id-input .content {
  }

  .payment-input-body .section .right-div {
    width: 100%;
    padding: 1rem;
  }

  .payment-input-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .payment-input-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .payment-input-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
  .payment-input-container {
    padding: 0rem 0.75rem;
  }

  .payment-input-container .payment-input-body .middle-container {
    width: 100%;
  }

  .payment-input-body .section {
    flex-direction: column;
  }

  .payment-input-body .section .id-input {
    width: 100%;
  }

  .payment-input-body .section .id-input .content {
    padding: 1rem;
  }

  .payment-input-body .section .value-input-waiting {
    display: none;
  }

  .payment-input-body .section .value-input {
    padding: 0rem;
  }

  .payment-input-body .section .id-input .content {
  }

  .payment-input-body .section .right-div {
    width: 100%;
    padding: 1rem;
  }

  .payment-input-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .payment-input-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .payment-input-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
