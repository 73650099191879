.deposit-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.deposit-body {
  width: 100%;
  display: flex;
}

.deposit-body .title {
  color: var(--outside-div-text-color);
  text-align: start;
  font-size: calc(1.1rem * var(--font-size-scale)) !important;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.deposit-body .loading-currencies {
  margin-top: 5rem;
}

.deposit-body .loading-conversions {
  margin-bottom: 1rem !important;
  width: 2rem !important;
  height: 2rem !important;
}

.deposit-body .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.deposit-body .section .left-input {
  width: 50%;
  height: fit-content;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  position: relative;
  animation: fade-in-up 0.6s;
}

.deposit-body .section .left-input .background {
  background-color: var(--div-background);
  border-radius: var(--div-border-radius);
  opacity: var(--div-opacity);
  width: 100%;
  height: 100%;
  position: absolute;
}

.deposit-body .section .left-input .content {
  border-radius: var(--div-border-radius);
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deposit-body .section .left-input .content .form-div {
  height: fit-content;
  width: 100%;
}

.deposit-body .section .left-input .content .form-div .danger {
  color: var(--inside-danger-color);
}

.deposit-body .section .left-input .content .message-div {
  color: var(--inside-div-text-color);
  font-style: italic;
  font-size: calc(0.85rem * var(--font-size-scale)) !important;
  margin-top: 1rem;
  flex-grow: 1;
}

.deposit-body .section .option-div {
  width: 100%;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
  animation: fade-in-up 0.6s;
}

.deposit-body .section .option-div .sub-title {
  text-align: start;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  /* font-style: italic; */
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  display: none;
}

.deposit-body .section .right-div {
  width: 50%;
  padding: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
}

.deposit-body .section .input-group .text-area {
  margin-bottom: 1rem;
}

.deposit-body .section .right-div .text-area {
  height: 4rem;
}

.deposit-body .section .value-input {
  width: 100%;
  height: 100%;
  padding: 1.25rem 0 0 0;
  animation: fade-in-up 0.6s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deposit-body .loader {
  width: 4rem !important;
  height: 4rem !important;
}

.deposit-body .error-form-text {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  color: crimson;
  font-weight: bold;

  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  line-height: 1rem;
  text-align: center;
  animation: bounce 0.5s;
}

.deposit-body .form-div .input-payment-method {
  width: 100%;
}

.deposit-body .form-div .input-payment-method .bar {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-left: 0 !important;
  color: var(--inside-input-text-color);
  border-radius: var(--inside-input-radius) !important;
  border: var(--inside-input-border);
  background: var(--inside-input-background);
  cursor: pointer;
}

.deposit-body .form-div .input-payment-method .no-cursor {
  cursor: default !important;
}
.deposit-body .form-div .input-payment-method .outside {
  color: var(--outside-input-text-color);
  border-radius: var(--outside-input-radius) !important;
  border: var(--outside-input-border);
  background: var(--outside-input-background);
}

.deposit-body .form-div .input-payment-method .bar .search-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.75rem;
}

.deposit-body .form-div .input-payment-method .bar .text {
  margin-left: 1rem;
  height: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.deposit-body .form-div .input-payment-method .bar .text .value-in-blox {
  margin-left: 0.4rem;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  opacity: 0.85;
  line-height: 1rem;
}

.deposit-body .form-div .input-payment-method .bar .browse-button {
  width: 7rem;
  height: 100%;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--inside-input-radius) var(--inside-input-radius) 0;
}

.deposit-body .form-div .input-payment-method .bar .icon {
  font-size: calc(2rem * var(--font-size-scale)) !important;
  background-color: white;
  margin-left: 0.75rem;
}

.deposit-body .form-div .input-payment-method .bar .eliminate-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}

.deposit-body .form-div .input-payment-method .currency {
  width: 50%;
  background-color: white;
  border-radius: var(--div-border-radius);
  border: none;
  display: flex;
  justify-content: end;
}

.deposit-body .deposit-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  align-items: center;
  overflow: auto;
  color: var(--outside-div-text-color);
}

.deposit-body .deposit-list ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.deposit-body .deposit-list ::-webkit-scrollbar-thumb {
  background: #888;
}

.deposit-body .deposit-list ::-webkit-scrollbar-track {
  background: white;
}

.deposit-body .deposit-list .deposit-row-container {
  width: 100%;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  cursor: pointer;
  margin-bottom: 1rem;
}

.deposit-body .deposit-list .deposit-row {
  width: 100%;
  height: 6rem;
  border-radius: var(--div-border-radius);
  position: relative;
}

.deposit-body .deposit-list .deposit-row .background {
  width: 100%;
  height: 100%;
  border-radius: var(--div-border-radius);
  background-color: var(--div-background);
  opacity: var(--div-opacity);
  position: absolute;
  z-index: 0;
}

.deposit-body .deposit-list .deposit-row .content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  position: relative;
  z-index: 1;
}

.deposit-body .deposit-list .deposit-row .metadata-column {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.deposit-body .deposit-list .deposit-row .metadata-column .icon {
  font-size: calc(2rem * var(--font-size-scale)) !important;
  margin-right: 0.75rem;
  color: var(--inside-highlight-color);
}

.deposit-body .deposit-list .deposit-row .metadata-column .option-name {
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
  font-weight: bold;
  text-align: start;
}

.deposit-body .deposit-list .deposit-row .metadata-column .option-description {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-style: italic;
  text-align: start;
}

.deposit-body .deposit-list .deposit-row .metadata-column .region {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-style: italic;
  text-align: start;
}

.deposit-body .deposit-list .deposit-row .arrow {
  color: var(--inside-div-text-color);
  font-size: calc(var(--arrow-icon-size) * var(--font-size-scale)) !important;
}

.deposit-body .qrcode {
  margin: 0 auto 0.75rem auto;
  width: 15rem;
  height: 15rem;
}

.deposit-body .key-div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.deposit-body .key-div .key {
  width: 75%;
  text-overflow: ellipsis;
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
  overflow: hidden;
  text-wrap: nowrap;
}

.deposit-body .key-div .copy-key-icon {
  margin-left: 0.5rem;
  font-size: calc(1.5rem * var(--font-size-scale)) !important;
}

.deposit-body .key-div .copy-success-icon {
  margin-left: 0.5rem;
  font-size: calc(1.5rem * var(--font-size-scale)) !important;
  color: var(--inside-success-color);
  animation: animate-fade 0.25s;
}

.deposit-modal .modal-dialog {
  max-width: fit-content;
}

.deposit-body .pix-div {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1500px) {
  .deposit-body .deposit-list .deposit-row {
    height: 5.5rem;
  }

  .deposit-body .qrcode {
    margin: 0 auto 0.75rem auto;
    width: 14rem;
    height: 14rem;
  }
}

@media only screen and (max-width: 1300px) {
  .deposit-body .deposit-list .deposit-row {
    height: 5rem;
  }

  .deposit-body .qrcode {
    margin: 0 auto 0.75rem auto;
    width: 13rem;
    height: 13rem;
  }
}

@media only screen and (max-width: 1200px) {
  .deposit-container .deposit-body .middle-container {
    width: 100%;
  }

  .deposit-body .section {
    flex-direction: column;
  }

  .deposit-body .section .left-input {
    width: 100%;
  }

  .deposit-body .section .left-input .content {
    padding: 1rem;
  }

  .deposit-body .section .value-input {
    padding: 0rem;
  }

  .deposit-body .section .left-input .content {
  }

  .deposit-body .section .right-div {
    width: 100%;
    padding: 1rem 0rem;
  }

  .deposit-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .deposit-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .deposit-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .deposit-body .form-div .input-payment-method .bar .text {
    font-size: calc(0.9rem * var(--font-size-scale)) !important;
  }
}

@media only screen and (max-width: 900px) {
  .deposit-body .deposit-list .deposit-row {
    height: 6rem;
  }
}

@media only screen and (max-width: 600px) {
  .deposit-container .deposit-body .middle-container {
    width: 100%;
  }

  .deposit-body .section {
    flex-direction: column;
  }

  .deposit-body .section .left-input {
    width: 100%;
  }

  .deposit-body .section .left-input .content {
    padding: 1rem;
  }

  .deposit-body .section .value-input {
    padding: 0rem;
  }

  .deposit-body .section .left-input .content {
  }

  .deposit-body .section .right-div {
    padding: 1rem 0rem;
  }

  .deposit-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .deposit-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .deposit-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .deposit-body .form-div .input-payment-method {
    width: 100%;
  }

  .deposit-body .form-div .input-payment-method .bar {
    font-size: calc(0.9rem * var(--font-size-scale)) !important;
  }

  .deposit-body .deposit-list .deposit-row-container {
    padding: 0;
    height: 6rem;
  }

  .deposit-body .deposit-list .deposit-row {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
