.detail-container-print {
  width: 2480px;
  height: 3508px;
  padding: 4rem 24rem;
  display: flex;
  flex-direction: column;
}

.detail-container-print .payment-detail-inner-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header
  .print-img {
  width: 5rem;
  aspect-ratio: var(--logo-aspect-ratio);
  margin-right: 1rem;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header
  .company-title {
  font-size: 5rem;
  font-weight: bold;
  color: black;
}

.detail-container-print .payment-detail-inner-div .payment-detail-title {
  font-size: 3rem;
  margin-bottom: 4rem;
  color: #333333;
  display: flex;
  justify-content: start;
}

.detail-container-print .payment-detail-inner-div .receipt-container {
  margin-bottom: 5rem;
}

.detail-container-print .payment-detail-inner-div .receipt-title {
  font-size: 2.5rem;
  color: #444444;
  margin-bottom: 1.5rem;
}

.detail-container-print .payment-detail-inner-div .receipt-row {
  display: flex;
  width: 100% !important;
  font-size: 2rem;
  padding: 1.25rem 1rem;
  border-bottom: 1px solid #a9a9a9;
}

.detail-container-print .payment-detail-inner-div .receipt-row .key {
  font-weight: bold;
  width: 50%;
}

.detail-container-print .payment-detail-inner-div .receipt-row .value {
  overflow: unset !important;
  display: block !important;
  width: 50%;
}

.detail-container-print .payment-detail-inner-div .payment-detail-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-info
  .partial-border {
  margin-top: 8rem;
  margin-bottom: 1.5rem;
  height: 1px;
  width: 70%;
  border-top: 1px solid black;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-info
  .info-row {
  font-size: calc(2rem);
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-footer {
  margin-top: auto;
  width: 100%;
  color: #999999;
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-footer
  .bottom-info-div {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.detail-container-print
  .payment-detail-company-footer
  .bottom-info-div
  .bottom-info {
  margin-right: 0.5rem;
}

.detail-container-print
  .payment-detail-company-footer
  .bottom-info-div
  .right-pipe {
  border-right: 1px solid #999999;
  margin-right: 1rem;
  padding-right: 1rem;
}

@media print {
  .detail-container-print .payment-detail-inner-div {
    padding: 5rem;
    display: flex;
    justify-content: center;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5rem;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header
    .print-img {
    width: 34.94px;
    height: 39px;
    margin-right: 1rem;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header
    .company-title {
    font-size: 1.5rem;
  }

  .detail-container-print .payment-detail-inner-div .payment-detail-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .detail-container-print .payment-detail-inner-div .receipt-row {
    width: 100%;
    font-size: 1rem;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-footer {
    margin-top: 5rem;
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    font-style: italic;
    justify-content: center;
  }
}
