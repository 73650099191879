:root {
}

.pay-vendor-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.pay-vendor-container .pay-vendor-body {
  width: 100%;
  display: flex;
}

.pay-vendor-container .pay-vendor-body .form-group {
  margin-top: 1.25rem;
}

.pay-vendor-container .pay-vendor-body .main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: var(--title-margin-bottom);
  text-align: start;
}

.pay-vendor-container .pay-vendor-body .top-section {
  width: 100%;
  display: flex;
  animation: fade-in-up 0.6s;
}

.pay-vendor-container .pay-vendor-body .top-section .from-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  border: var(--div-border);
  color: var(--inside-div-text-color);
  text-align: start;
  padding: 1.25rem;
}

.pay-vendor-container .pay-vendor-body .top-section .from-container .title {
  font-size: calc(1.05rem * var(--font-size-scale));
}

.pay-vendor-container .pay-vendor-body .top-section .to-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
  text-align: start;
  padding: 1.25rem;
}

.pay-vendor-container .pay-vendor-body .bottom-section {
  animation: fade-in-up 0.6s;
  margin-top: 1.25rem;
  color: var(--outside-div-text-color);
}

.pay-vendor-container .pay-vendor-body .top-section .to-container .title {
  font-size: calc(1.05rem * var(--font-size-scale));
}

.pay-vendor-container .pay-vendor-body .create-vendor-form {
  animation: fade-in-up 0.6s;
}

.pay-vendor-container .pay-vendor-body .error-message {
  margin-top: 1rem;
  color: var(--inside-danger-color);
  text-align: center;
}

.pay-vendor-container .pay-vendor-body .buttons-div {
  animation: fade-in-up 0.6s;
}

.pay-vendor-container .pay-vendor-body .total-balance-info {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.75rem;
}

.pay-vendor-container .pay-vendor-body .total-balance-info .info-column {
  display: flex;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: start;
}

.pay-vendor-container .pay-vendor-body .total-balance-info .info-column .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.pay-vendor-container .pay-vendor-body .total-balance-info .info-column .text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: calc(100% - var(--image-width));
  margin-left: 0.5rem;
}

.pay-vendor-container
  .pay-vendor-body
  .total-balance-info
  .info-column
  .text
  .username {
  width: 100%;
  font-size: calc(0.95rem * var(--font-size-scale));
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pay-vendor-container
  .pay-vendor-body
  .total-balance-info
  .info-column
  .text
  .region {
  width: 100%;
  font-size: calc(0.85rem * var(--font-size-scale));
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pay-vendor-container .pay-vendor-body .total-balance-info .total-column {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  align-items: end;
  justify-content: center;
  position: relative;
  font-size: calc(1rem * var(--font-size-scale));
}

.pay-vendor-container .pay-vendor-body .to-info {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 4.75rem;
}

.pay-vendor-container .pay-vendor-body .to-info .to-info-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: calc(0.95rem * var(--font-size-scale));
}

.pay-vendor-container .pay-vendor-body .to-info .to-info-row .name {
}

.pay-vendor-container .pay-vendor-body .to-info .to-info-row .value {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  text-align: end;
}

.pay-vendor-container .pay-vendor-body .payment-method-info-container .title {
  text-align: start;
  margin-bottom: 0.5rem;
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: bold;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .payment-method-info-div-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .payment-method-info-div {
  min-height: 6.5rem;
  overflow: hidden;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .show-more {
  grid-template-rows: 1fr;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .payment-method-info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: calc(0.9rem * var(--font-size-scale));
  margin-bottom: calc(0.25rem * var(--font-size-scale));
  border-bottom: 1px dotted var(--outside-div-text-color);
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .payment-method-info-row
  .key {
  text-align: start;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .payment-method-info-row
  .value {
  width: 70%;
  font-size: calc(0.9rem * var(--font-size-scale));
  text-align: end;
}

.pay-vendor-container
  .pay-vendor-body
  .payment-method-info-container
  .show-more-button {
  font-size: calc(0.85rem * var(--font-size-scale));
}

.pay-vendor-container .error-form-text {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

.pay-vendor-container .loading-conversions-div {
  margin: auto;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .pay-vendor-container {
  }

  .pay-vendor-container .pay-vendor-body .middle-container {
    width: 100%;
  }

  .pay-vendor-container .pay-vendor-body .vendor-form-div {
    width: 100%;
  }

  .pay-vendor-container .pay-vendor-body .top-section {
    flex-wrap: wrap;
  }

  .pay-vendor-container .pay-vendor-body .top-section .from-container {
    width: 100%;
    padding: 1rem;
  }

  .pay-vendor-container .pay-vendor-body .top-section .to-container {
    width: 100%;
    padding: 1rem;
  }

  .pay-vendor-container .pay-vendor-body .total-balance-info {
    height: unset;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
