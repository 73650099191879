:root {
}

.transactions-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.transactions-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.transactions-container .transactions-body .middle-container {
  height: 100%;
}

.transactions-body .div-cards {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.transactions-body .div-cards .card-transactions {
  margin: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  color: var(--inside-div-text-color);
  border: var(--div-border);
  height: 6rem;
  position: relative;
  border-radius: var(--div-border-radius);
}

.transactions-body .div-cards .card-transactions:hover {
  transform: scale(1.0075);
}

.transactions-body .div-cards .card-transactions .background {
  background: var(--div-background) !important;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: var(--div-opacity);
  z-index: 0;
  border-radius: var(--div-border-radius);
}

.transactions-body .div-cards .card-transactions .content {
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-radius: var(--div-border-radius);
}

.transactions-body .div-cards .card-transactions .metadata-column {
  display: flex;
  width: 30%;
}

.transactions-body .div-cards .card-transactions .metadata-column .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--success-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.transactions-body
  .div-cards
  .card-transactions
  .metadata-column
  .image-danger {
  background-color: var(--inside-danger-color);
}

.transactions-body
  .div-cards
  .card-transactions
  .metadata-column
  .image
  .interior-letter {
  font-size: 1.25rem;
  color: var(--div-background);
}

.transactions-body .div-cards .card-transactions .metadata-column .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
}

.transactions-body .div-cards .card-transactions .metadata-column .text .title {
  font-weight: bold;
  font-size: 1.25rem;
}

.transactions-body .div-cards .card-transactions .metadata-column .text .date {
  font-size: 1rem;
  display: flex;
}

.transactions-body .div-cards .card-transactions .operation-number-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 25%;
}

.transactions-body .div-cards .card-transactions .amount-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 20%;
}

.transactions-body .div-cards .card-transactions .amount-column .amount {
  font-weight: bold;
  font-size: 1rem;
  color: var(--inside-success-color);
}

.transactions-body .div-cards .card-transactions .amount-column .amount-danger {
  color: var(--inside-danger-color);
}

.transactions-body .accordion-transaction-history {
}

.transactions-body .accordion-transaction-history .accordion-item {
  color: var(--inside-div-text-color);
  border: none;
  background: none;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header {
  background: none;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-body {
  padding: 0.5em 0rem 1rem 0rem;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button {
  background: none;
  color: var(--outside-highlight-color);
  font-weight: bold;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  box-shadow: none;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background-image: var(--accordion-icon);
}

.transactions-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)::after {
  background-image: var(--accordion-icon);
}

.transactions-body .skeleton-card-seperator {
  height: var(--filter-height);
}

.transactions-body .card-transactions .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.transactions-body .div-cards .card-transactions .metadata-column-skeleton {
  width: 30%;
  display: flex;
  align-items: center;
}

.transactions-body
  .div-cards
  .card-transactions
  .metadata-column-skeleton
  .image-skeleton {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--skeleton-background-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.transactions-body
  .div-cards
  .card-transactions
  .metadata-column-skeleton
  .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.div-cards
  .card-transactions
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.div-cards
  .card-transactions
  .metadata-column-skeleton
  .text-skeleton
  .date-skeleton {
  background-color: var(--skeleton-background-color);
  margin-top: 0.5rem;
  height: 0.7rem;
  width: 80%;
  border-radius: var(--div-border-radius);
}

.transactions-body .div-cards .card-transactions .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 30%;
}

.transactions-body
  .div-cards
  .card-transactions
  .balance-column-skeleton
  .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 50%;
}

.transactions-body
  .div-cards
  .card-transactions
  .operation-number-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 20%;
}

.transactions-body
  .div-cards
  .card-transactions
  .operation-number-column-skeleton
  .operation-number-title-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 100%;
}

.transactions-body
  .div-cards
  .card-transactions
  .operation-number-column-skeleton
  .operation-number-text-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 450px) {
  :root {
  }

  .transactions-container {
  }

  .transactions-container .transactions-body .middle-container {
    width: 100%;
  }

  .transactions-body .div-cards {
    max-height: unset;
  }

  .transactions-body .div-cards .card-transactions {
    width: 96% !important;
    margin: 0.4em 2% 0.4rem 2%;
    transition: none;
  }

  .transactions-body .div-cards .card-transactions .content {
    padding: 0.5rem;
  }

  .transactions-body .div-cards .card-transactions .metadata-column {
    width: 45%;
  }

  .transactions-body .skeleton-card-seperator {
    display: none;
  }

  .transactions-body .div-cards .card-transactions .metadata-column-skeleton {
    width: 43%;
  }

  .transactions-body
    .div-cards
    .card-transactions
    .operation-number-column-skeleton {
    width: 25%;
  }

  .transactions-body .div-cards .card-transactions .balance-column-skeleton {
    width: 22%;
  }
}
