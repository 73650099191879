.deposit-body .fee-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--outside-div-text-color);
  font-size: calc(0.85rem * var(--font-size-scale));
}

.deposit-body .fee-div .fee-title {
  font-size: calc(0.9rem * var(--font-size-scale));
  margin-top: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  text-align: start;
}

.deposit-body .fee-div .info-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px dotted var(--outside-div-text-color);
}

.deposit-body .fee-div .fee-row {
  display: flex;
  justify-content: space-between;
}

.deposit-body .loading-quote {
  margin-top: 1rem;
}
