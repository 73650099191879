:root {
}

.vendor-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.vendor-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.vendor-container .vendor-body .middle-container {
  height: 100%;
}

.vendor-body .search-vendor-detail {
  margin-top: 1rem;
}

.vendor-body .title-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--title-margin-bottom);
}

.vendor-body .title-div .title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  text-align: start;
}

.vendor-body .title-div .add-vendor-button {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-body .bottom-content {
  animation: fade-in-up 0.6s;
}

.vendor-body .div-cards {
  overflow: auto;
  max-height: 80vh;
  margin-top: var(--margin-content-under-tab);
}

.vendor-body .div-cards .card-vendor {
  margin: 0.5rem 0rem 1rem 0rem;
  transition: all 0.15s ease-in-out;
  color: var(--inside-div-text-color);
  border: var(--div-border);
  height: 7rem;
  position: relative;
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: calc(0.85rem * var(--font-size-scale));
}

.vendor-body .div-cards .selected-card {
  border: none;
  color: var(--selected-text-color);
  background: var(--selected-background-color);
}

.vendor-body .div-cards .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0.5rem 0.5rem 0.25rem 0.5rem;
  padding: 0.75rem 1rem;
  font-weight: bold;
  color: var(--outside-div-text-color);
  font-size: 0.9rem;
}

.vendor-body .div-cards .name-column {
  text-align: start;
  width: 22.25%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vendor-body .div-cards .type-column {
  text-align: start;
  width: 22.25%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vendor-body .div-cards .metadata-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  width: 40%;
  min-height: 1rem;
}

.vendor-body .div-cards .metadata-column .metadata-data {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 100%;
  text-align: start;
}

.vendor-body .div-cards .button-column {
  width: 15%;
  min-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
}

.vendor-body .div-cards .button-column .icon {
  margin-right: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
}

.vendor-body .div-cards .account-column {
  width: 20%;
  text-align: start;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.vendor-body .div-cards .amount-column {
  width: 15%;
  text-align: start;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.vendor-body .div-cards .date-column {
  width: 20%;
  text-align: start;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.vendor-body .div-cards .description-column {
  width: 25%;
  text-align: start;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.vendor-body .div-cards .paid-with-column {
  width: 20%;
  text-align: start;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.vendor-body .div-cards .status-column {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: 2rem;
}

.vendor-body .div-cards .icons-column {
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: end;
  font-size: 1.5rem;
}

.vendor-body .div-cards .icons-column .right-icon {
  margin-right: 0.5rem;
}

.vendor-body .div-cards .icon:hover {
  transform: scale(1.1);
}

.vendor-body .skeleton-card-seperator {
  height: var(--filter-height);
}

.vendor-body .card-vendor .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.vendor-body .div-cards .card-vendor .metadata-column-skeleton {
  width: 30%;
  display: flex;
  align-items: center;
}

.vendor-body .div-cards .card-vendor .metadata-column-skeleton .image-skeleton {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--skeleton-background-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.vendor-body .div-cards .card-vendor .metadata-column-skeleton .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.div-cards
  .card-vendor
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.div-cards
  .card-vendor
  .metadata-column-skeleton
  .text-skeleton
  .abbreviation-skeleton {
  background-color: var(--skeleton-background-color);
  margin-top: 0.5rem;
  height: 0.7rem;
  width: 80%;
  border-radius: var(--div-border-radius);
}

.vendor-body .div-cards .card-vendor .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 30%;
}

.vendor-body .div-cards .card-vendor .balance-column-skeleton .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 75%;
}

.vendor-body
  .div-cards
  .card-vendor
  .balance-column-skeleton
  .percentage-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 0.9rem;
  width: 50%;
  margin-top: 0.5rem;
}

.vendor-body .vendor-detail-div {
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  color: var(--inside-div-text-color);
  margin: 0rem 1rem;
  padding: 1rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fade-in-up 0.6s;
  margin-top: calc(var(--title-margin-bottom) + 2.25rem + 0.6rem);
}

.vendor-body .vendor-detail-div .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendor-body .vendor-detail-div .header .title {
  font-weight: bold;
  font-size: 1.1rem;
}

.vendor-body .vendor-detail-div .header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.vendor-body .vendor-detail-div .body {
  padding: 1rem 0;
  font-style: italic;
}

.vendor-body .vendor-detail-div .body .list-payment-methods {
  overflow: auto;
  max-height: 12rem;
}

.vendor-body .vendor-detail-div .body .list-payment-methods .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0.5rem 0rem 0.25rem 0rem;
}

.vendor-body
  .vendor-detail-div
  .body
  .list-payment-methods
  .payment-method-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  height: 3rem;
  border: var(--inside-highlight-color) 1px solid;
  border-radius: var(--div-border-radius);
  margin: 0.5rem 0rem;
  padding: 0.5rem;
}

.vendor-body
  .vendor-detail-div
  .body
  .list-payment-methods
  .payment-method-row
  .icon {
  font-size: 1.2rem;
  cursor: pointer;
}

.vendor-body .vendor-detail-div-history {
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  color: var(--inside-div-text-color);
  margin: 0rem 1rem;
  padding: 1rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: calc(var(--title-margin-bottom) + 2.25rem + 0.6rem);
  animation: fade-in-up 0.6s;
}

.vendor-body .vendor-detail-div-history .loader {
  width: 3rem !important;
  height: 3rem !important;
}

.vendor-body .vendor-detail-div-history .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendor-body .vendor-detail-div-history .header .title {
  font-weight: bold;
  font-size: 1.1rem;
}

.vendor-body .vendor-detail-div-history .header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.vendor-body .vendor-detail-div-history .body {
  padding: 1rem 0;
  font-style: italic;
}

.vendor-body .vendor-detail-div-history .body .list-payments {
  overflow: auto;
  max-height: 12rem;
}

.vendor-body .vendor-detail-div-history .body .list-payments .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0.5rem 0rem 0.25rem 0rem;
}

.vendor-body .vendor-detail-div-history .body .list-payments .to-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 45%;
}

.vendor-body .vendor-detail-div-history .body .list-payments .date-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 30%;
}

.vendor-body .vendor-detail-div-history .body .list-payments .amount-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 25%;
}

.vendor-body .vendor-detail-div-history .body .list-payments .payments-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  height: 3rem;
  border: var(--inside-highlight-color) 1px solid;
  border-radius: var(--div-border-radius);
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  cursor: pointer;
}

.vendor-body .bottom-content .info-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(0.9rem * var(--font-size-scale));
  margin-top: 1rem;
  color: var(--outside-div-text-color);
}

.vendor-body .bottom-content .info-div .info-row {
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.vendor-body .bottom-content .info-div .info-row .key {
  font-weight: bold;
  text-align: start;
}

.vendor-body .bottom-content .info-div .info-row .value {
  text-align: end;
}

.approve-text {
  margin-bottom: 0.75rem;
}

.detail-container {
  display: flex;
  flex-direction: column;
}

.detail-container .icons-row {
  display: flex;
  width: 100%;
  font-size: calc(2.5rem * var(--font-size-scale));
  justify-content: center;
  margin-top: 2rem;
}

.detail-container .icons-row .left-icon {
  margin-right: 3rem;
  cursor: pointer;
}

.detail-container .icons-row .right-icon {
  cursor: pointer;
}

.payment-detail-footer a {
  font-weight: bold;
  color: var(--outside-highlight-color);
  text-decoration: none;
}

.vendor-detail-modal-footer {
  display: flex;
  justify-content: space-between;
}

.vendor-detail-modal-footer .reject-button {
  margin-right: 0.25rem;
}

@media only screen and (max-width: 1500px) {
  .vendor-body .side-container {
    width: 20%;
  }

  .vendor-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .vendor-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .vendor-container {
  }

  .vendor-body {
  }

  .vendor-container .vendor-body .middle-container {
    width: 100%;
  }

  .vendor-body .title-div .title {
    text-align: start;
  }

  .vendor-body .title-div .add-vendor-button {
    padding-right: 0rem;
    text-align: end;
  }

  .vendor-body .div-cards {
    max-height: unset;
    margin-top: 0rem;
  }

  .vendor-body .div-cards .headers {
    margin: 0.5rem 0rem 0rem 0rem;
  }

  .vendor-body .div-cards .card-vendor {
    width: 100% !important;
    margin: 0.4em 0rem;
    transition: none;
  }

  .vendor-body .div-cards .name-column {
    width: 30%;
  }

  .vendor-body .div-cards .name-column .name {
    font-size: 1.1rem;
  }

  .vendor-body .div-cards .name-column .region {
  }

  .vendor-body .div-cards .metadata-column {
    display: none;
  }

  .vendor-body .div-cards .button-column {
    display: flex;
    width: 25%;
  }

  .vendor-body .div-cards .account-column {
    width: 25%;
  }

  .vendor-body .div-cards .description-column {
    display: none;
  }

  .vendor-body .div-cards .paid-with-column {
    display: none;
  }

  .vendor-body .skeleton-card-seperator {
    display: none;
  }

  .vendor-body .div-cards .card-vendor .metadata-column-skeleton {
    width: 43%;
  }

  .vendor-body .div-cards .card-vendor .balance-column-skeleton {
    width: 22%;
  }

  .vendor-body .small-button {
    width: 100%;
    margin-top: 1rem;
  }

  .detail-container .detail-row {
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  .detail-container .detail-row .value {
    text-align: end;
  }

  .vendor-body .div-cards .icons-column {
    width: 4rem;
  }
}
