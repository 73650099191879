.forgot-password-container {
  height: var(--result-div-height);
}

.login-container .login-2fa-div .description {
  margin-bottom: 1rem;
  margin-top: calc(var(--login-div-height) / 9);
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .forgot-password-container {
    height: unset;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
