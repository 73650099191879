.transaction-confirmation-container {
  width: 100%;
}

.transaction-confirmation-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fade-in-up 0.6s;
  height: 48rem;
}

.transaction-confirmation-body .confirmation-main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: 1.5rem;
  text-align: start;
}

.transaction-confirmation-body .amount-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--div-border-radius);
  border: var(--outside-highlight-color) solid 0.15rem;
  color: var(--outside-div-text-color);
  padding: 1.25rem;
  width: 100%;
  height: 6rem;
  margin-bottom: 0.5rem;
}

.transaction-confirmation-body .amount-div .amount {
  font-size: 1.05rem;
  font-weight: 500;
}

.transaction-confirmation-body .amount-div .equal {
  font-size: 1.1rem;
  font-style: italic;
}

.transaction-confirmation-body .card-title {
  padding-left: 0.5rem;
  color: var(--outside-div-text-color);
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.transaction-confirmation-body .hidden-icons-div {
  padding-left: 0.5rem;
  color: var(--outside-div-text-color);
  font-size: 1.05rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}

.transaction-confirmation-body .account-info {
  width: 100%;
  height: 8rem;
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  border: var(--div-border);
  background-color: var(--div-background);
  box-shadow: var(--div-shadow-border);
}

.transaction-confirmation-body .account-info {
  padding: 1rem;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.transaction-confirmation-body .account-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-confirmation-body .account-info .left {
  display: flex;
}

.transaction-confirmation-body .account-info .left .image {
  display: none;
  width: 4rem;
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.transaction-confirmation-body .account-info .left .text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: var(--inside-div-text-color);
}

.transaction-confirmation-body .account-info .left .text .title {
  font-size: 1.05rem;
  font-weight: bold;
}

.transaction-confirmation-body .account-info .left .text .id {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transaction-confirmation-body .account-info .favorite-div {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  text-align: start;
  color: var(--inside-highlight-color);
  font-weight: 600;
  cursor: pointer;
  align-self: end;
}

.transaction-confirmation-body .account-info .favorite-div .favorite-icon {
  font-size: 2.5rem;
  margin-right: 0.25rem;
  color: var(--inside-highlight-color);
}

.transaction-confirmation-body .from-div {
  width: 100%;
  height: 8rem;
  border-radius: var(--div-border-radius);
  margin-bottom: 1rem;
  color: var(--inside-div-text-color);
  position: relative;
}

.transaction-confirmation-body .from-div .background {
  width: 100%;
  height: 100%;
  background-color: var(--div-background);
  position: absolute;
  opacity: var(--div-opacity);
  z-index: 0;
  border-radius: var(--div-border-radius);
}

.transaction-confirmation-body .from-div {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  border-radius: var(--div-border-radius);
}

.transaction-confirmation-body .from-div .info-column {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: start;
}
.transaction-confirmation-body .from-div .info-column .text {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  align-items: start;
}

.transaction-confirmation-body .from-div .info-column .text .title {
  font-size: 1.1rem;
  font-weight: bold;
}

.transaction-confirmation-body .from-div .info-column .text .username {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.transaction-confirmation-body .from-div .info-column .text .region {
  font-size: 0.85rem;
  font-style: italic;
  margin-top: 0.3rem;
}

.transaction-confirmation-body .from-div .total-column {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  align-items: end;
  justify-content: center;
}

.transaction-confirmation-body .from-div .total-column .total {
  font-size: 1.1rem;
  font-weight: 700;
}

.transaction-confirmation-body .from-div .total-column .number {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.3rem;
}

.transaction-confirmation-body .alert-div {
  color: #0f5132;
  background-color: #d1e7dd;
  border-radius: var(--div-border-radius);
  border: #badbcc 1px solid;
  padding: 1rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: none;
}

.transaction-confirmation-body .alert-div .icon {
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.transaction-confirmation-body .alert-div .alert-text {
  text-align: start;
}

.transaction-confirmation-body .buttons-div {
  animation: none !important;
}

.transaction-confirmation-body .hide {
  cursor: pointer;
  font-size: var(--total-balance-icons-font-size);
  animation: fade-in 0.25s;
}

.transaction-confirmation-body .form-div {
  margin-top: 2rem;
}

.transaction-confirmation-body .form-div .auth-div {
  color: var(--outside-div-text-color);
  padding: 0.5rem;
  font-size: 0.9rem;
  margin-top: 1rem;
  flex-grow: 1;
}

.transaction-confirmation-body .form-div .auth-div b {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .transaction-confirmation-body .payment-option-container {
    width: 100%;
  }

  .transaction-confirmation-container
    .transaction-confirmation-body
    .middle-container {
    width: 100%;
  }

  .transaction-confirmation-body {
    width: 100%;
  }

  .transaction-confirmation-body .payment-option .payment-option-row-container {
    padding: 0rem;
  }

  .transaction-confirmation-body .amount-div .amount {
    font-size: 1.2rem;
  }

  .transaction-confirmation-body .account-info .left .image {
    width: 3rem;
    height: 3.4rem;
  }

  .transaction-confirmation-body .buttons-div {
    flex-direction: column;
    margin-top: 2rem;
  }

  .transaction-confirmation-body .buttons-div .confirm-button {
    width: 100%;
  }

  .transaction-confirmation-body .buttons-div .cancel-button {
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .transaction-confirmation-body .account-info .favorite-div {
    justify-content: end;
  }

  .transaction-confirmation-body .account-info .favorite-div .favorite-icon {
    font-size: 1.75rem;
  }

  .transaction-confirmation-body .account-info .favorite-div .add-text {
    width: 4rem;
  }
}
