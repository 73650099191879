:root {
  --body-padding-top: calc(var(--padding-top-base) + var(--nav-bar-height));
  --tab-height: 2.8rem;
  --title-margin-bottom: 2rem;
  --welcome-div-container-height: 145vh;
  --margin-content-under-tab: 1.5rem;
}

.welcome-div-container {
  width: 100%;
  max-width: 1850px;
  min-height: 145vh;
  margin: auto;
  padding-top: var(--body-padding-top);
}

.welcome-div {
  height: 50%;
  width: 50%;
  margin: auto;
  color: var(--inside-div-text-color);
}

.welcome-div p {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--inside-div-text-color);
}

.no-background-input-inside {
  color: var(--inside-div-text-color) !important;
  background-color: transparent !important;
  border: none;
  display: flex;
  flex-grow: 1;
  border-radius: 0;
  border-bottom: var(--inside-highlight-color) 1px solid;
}

.no-background-input-inside:focus {
  border: none;
  box-shadow: none !important;
  outline: none;
  border-bottom: var(--inside-highlight-color) 1px solid;
}

.no-background-input-inside:disabled {
  cursor: not-allowed !important;
}

.inside-input-symbol {
  background: none;
  border: none;
  border-radius: 0;
  font-size: calc(0.9rem * var(--font-size-scale));
  color: var(--inside-div-text-color);
  border-bottom: var(--inside-highlight-color) 1px solid;
  height: 2.5rem;
  transition: all 0.15s ease-in-out;
}

.no-background-input-outside {
  color: var(--outside-div-text-color) !important;
  background-color: transparent !important;
  border: none;
  display: flex;
  flex-grow: 1;
  border-radius: 0;
  border-bottom: var(--outside-highlight-color) 1px solid;
}

.no-background-input-outside:focus {
  border: none;
  box-shadow: none !important;
  outline: none;
  border-bottom: var(--outside-highlight-color) 1px solid;
}

.outside-input-symbol {
  background: none;
  border: none;
  border-radius: 0;
  color: var(--outside-div-text-color);
  border-bottom: var(--outside-highlight-color) 1px solid;
  height: 2.5rem;
  transition: all 0.15s ease-in-out;
}

.input-symbol-danger {
  color: var(--inside-danger-color) !important;
  border-bottom: var(--inside-danger-color) 1px solid !important;
}

.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--filter-height);
  width: 100%;
  z-index: 100;
  animation: fade-in-up 0.6s;
}

.tab-header .tab-buttons {
  display: flex;
  justify-content: space-between;
  color: var(--outside-highlight-color);
  padding-left: 0.5rem;
  overflow: auto;
}

.tab-header .tab-buttons::-webkit-scrollbar {
  display: none;
}

.tab-header .tab-buttons .tab-button {
  padding: 0.5rem 1rem;
  font-size: calc(1.05rem * var(--font-size-scale));
  opacity: 0.5;
  text-wrap: nowrap;
}

.tab-header .tab-buttons .tab-picked {
  display: flex;
  justify-content: space-between;
  color: var(--outside-highlight-color);
  opacity: 1 !important;
  border-bottom: 2px solid var(--outside-highlight-color) !important;
}

.filter-search-div {
  display: flex;
  padding: 0.8rem 0.5rem;
  height: var(--filter-height);
}

.search-bar {
  color: var(--outside-highlight-color);
  padding: 0px 1rem;
  border: 1px var(--outside-highlight-color) solid;
  border-radius: var(--div-border-radius);
  height: 100%;
  margin-left: auto;
}

.search-bar .search-icon {
  margin: auto;
}

.search-bar #search-bar-entities {
  color: var(--outside-div-text-color);
  background: none;
  border: none;
  box-shadow: none !important;
  margin: auto;
  text-overflow: ellipsis;
}

.search-bar #search-bar-entities::placeholder {
  color: var(--outside-highlight-color);
}

.tab-content {
  animation: fade-in-up 0.6s;
}

.no-list-div {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  color: var(--outside-div-text-color);
  align-items: center;
}

.no-list-div .empty-icon {
  font-size: calc(3rem * var(--font-size-scale));
}

.no-list-div .title {
  font-weight: bold;
  font-size: calc(1.1rem * var(--font-size-scale));
  margin-top: 0.4rem;
}

.no-list-div .sub-title {
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: 100;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1900px) {
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1400px) {
  :root {
    --nav-bar-height: 5rem !important;
    --body-padding-top: calc(var(--padding-top-base) + var(--nav-bar-height));
    --tab-height: 2.8rem;
    --title-margin-bottom: 2rem;
    --welcome-div-container-height: 145vh;
    --margin-content-under-tab: 1.25rem;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
    --nav-bar-height: 4.75rem !important;
    --margin-content-under-tab: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  :root {
    --search-bar-height: 2.5rem;
    --tab-height: 2.8rem;
    --title-margin-bottom: 1.5rem;
  }

  .welcome-div-container {
    padding-top: calc(var(--nav-bar-height));
    padding-bottom: 4rem;
    height: unset;
  }

  .tab-header {
    flex-direction: column;
    padding: 0rem;
    height: fit-content;
  }

  .tab-header .tab-buttons {
    padding-left: 0;
    width: 100%;
    justify-content: start;
    align-items: center;
  }

  .filter-search-div {
    width: 100%;
    padding: 0.8rem 0rem;
  }

  .search-bar {
    height: var(--search-bar-height);
  }
}

@media only screen and (max-width: 320px) {
}
