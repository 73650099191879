:root {
  --page-row-height: 3.5rem;
}

.search-pages-container {
  padding: 0 2rem;
  margin-top: calc(var(--total-balance-icons-height));
}

.search-pages-container .filter-search-div {
  padding: 0rem;
  height: calc(var(--filter-height) - 1.6rem);
}

.search-pages-container .div-pages {
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
}

.search-pages-container .div-pages .page-row {
  height: var(--page-row-height);
  background: var(--div-background);
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  padding: 1rem;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.search-pages-container .div-pages .page-row:hover {
  background: var(--selected-background-color);
  color: var(--selected-text-color);
  border: none;
}

.search-pages-container .div-pages .page-row .arrow {
}

@media only screen and (max-width: 1500px) {
  .search-pages-container {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
